.quote-container {
    perspective: 1000px;
}

.quote-text {
    display: inline-block;
    position: relative;
    opacity: 0;
    transform: rotateY(90deg);
    color: #000;
}

@keyframes fade-rotate-0 {
    0% {
        opacity: 0;
        transform: rotateX(90deg);
    }
    25% {
        opacity: 1;
        transform: rotateX(0deg);
    }
    75% {
        opacity: 1;
        transform: rotateX(0deg);
    }
    100% {
        opacity: 0;
        transform: rotateX(-90deg);
    }
}

@keyframes fade-rotate-1 {
    0% {
        opacity: 0;
        transform: rotateX(-90deg);
    }
    25% {
        opacity: 1;
        transform: rotateX(0deg);
    }
    75% {
        opacity: 1;
        transform: rotateX(0deg);
    }
    100% {
        opacity: 0;
        transform: rotateX(90deg);
    }
}

.quote-text.fade-rotate-0 {
    animation: fade-rotate-0 6s linear infinite;
}

.quote-text.fade-rotate-1 {
    animation: fade-rotate-1 6s linear infinite;
}
